const bells = document.getElementsByClassName('notification-bell');
const markAllAsRead = document.getElementsByClassName('js-mark-all-as-read');
const mobileToggle = document.getElementsByClassName('js-mobile-notifications-toggle');
const badges = document.getElementsByClassName('js-navigation__notifications-count');
const notificationMenuView = {
  desktopMenu: null,
  mobileMenu: null
};

document.onkeydown = function (e) {
  e = e || window.event;
  if (e.keyCode == 27 && bells.length > 0) {
    [...bells].forEach((bell) => {
      const dropdown = bell.getAttribute('data-toggle');

      bell.classList.remove('hover');
      if (dropdown) {
        document.getElementById(dropdown).classList.remove('is-open');
      }
    });
  }
};

const handleBell = function (bell) {
  const containers = document.getElementsByClassName('notification-dropdown__content');

  [...containers].forEach((container) => {
    // eslint-disable-next-line no-undef
    if (Foundation.MediaQuery.current == 'small') {
      container.style.height = `${window.innerHeight - 131}px`;
    }
  });

  if (bell === undefined || notificationMenuView === undefined) {
    return
  }

  const menuView = notificationMenuView[bell.getAttribute('data-menu-view-id')]
  if (menuView) {
    menuView.show();
  }
};

const setNotificationsAsRead = function (notifications) {
  const bell = $('.notification-bell').get(0);
  const url = bell.getAttribute('data-url');
  if (!url || notifications.length === 0) return Promise.resolve();

  const csrftoken = Cookies.get('csrftoken'); // eslint-disable-line no-undef

  const promise = fetch(url, {
    credentials: 'same-origin',
    method: 'post',
    headers: {
      'X-CSRFToken': csrftoken
    },
    body: JSON.stringify({ notifications, "viewOn": document.currentDateTime })
  }).then((response) => {
    if (response.status !== 200) {
      console.error(`open-notification error: ${response}`);
      if (window.Raven) {
        // eslint-disable-next-line no-undef
        window.Raven.captureMessage(`open-notifications error: ${response}`);
      }
    } else {
      const counters = document.getElementsByClassName('js-navigation__notifications-count');
      [...counters].forEach((counter) => {
        counter.style.display = "none";
      });

      const noNotificationCounters = document.getElementsByClassName('js-navigation__no-notifications-count');
      [...noNotificationCounters].forEach((counter) => {
        counter.style.display = "block";
      });

      const indicators = document.getElementsByClassName('user-notification__new-notification');
      [...indicators].forEach((indicator) => {
        indicator.style.display = "none";
      });

      [...markAllAsRead].forEach((button) => {
        button.setAttribute('disabled', "");
      });
    }
  }).catch((err) => {
    console.error(`open-notifications error: ${err}`);
    if (window.Raven) {
      // eslint-disable-next-line no-undef
      window.Raven.captureMessage(`open-notifications error: ${err}`);
    }
  });

  return promise;
}

const markAllAsSeen = function () {
  const notifications = _.uniq($('.js-notification-container[data-notification-unread]').map(function () {
    const $notification = $(this);
    return $notification.attr('data-notification-id');
  }));

  setNotificationsAsRead(notifications).then(() => {
    $('#notification-bell.hover').focus();
  });
};

[...bells, ...badges].forEach((bell) => {
  bell.addEventListener("keydown", function (event) {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13 || event.keyCode === 32) {
      handleBell(bell);
    }
  });

  bell.addEventListener('click', () => {
    handleBell(bell);
  });
});

function toggleMobileUserMenu(dropdown) {
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const $dropdown = $(dropdown);
  // Handle bottom sign out button positioning
  const mobileUserMenu = $('#user-dropdown-2 .mm-panel');
  if (mobileUserMenu.hasScrollBar()) {
    $dropdown.find(".menu-footer.bottom-menu").addClass("hide-for-js");
    $dropdown.find(".menu-footer.inside-menu-js").removeClass("hide-for-js");
    $dropdown.find(".mm-panels").addClass("bottom0");
  } else {
    $dropdown.find(".menu-footer.bottom-menu").removeClass("hide-for-js");
    $dropdown.find(".menu-footer.inside-menu-js").addClass("hide-for-js");
    $dropdown.find(".mm-panels").removeClass("bottom0");
  }
}

function toggleDropdownMenu(dropdown) {
  const $dropdown = $(dropdown);
  const dropdownID = $dropdown.attr("id");
  document.querySelector('body').classList.toggle('is-unscrollable');
  dropdown.classList.toggle('is-mobile');
  dropdown.classList.toggle('is-open');

  if (!dropdown.classList.contains("is-open")) {
    $dropdown.tabloop();
    $(`.js-mobile-notifications-toggle[data-mobiletoggle=${dropdownID}]`).first().focus();
    $dropdown.attr("aria-hidden", true);
  } else {
    $dropdown.tabloop();
    $dropdown.find(".js-focus-first").focus();
    $dropdown.attr("aria-hidden", false);
  }
}

function handleMobileNotification(e) {
  e.preventDefault();

  const dropdownID = e.target.closest('.js-mobile-notifications-toggle').getAttribute('data-mobiletoggle');
  const dropdown = document.getElementById(dropdownID);

  toggleDropdownMenu(dropdown);
}

function handleMobileUserMenu(e) {
  e.preventDefault();
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const dropdownID = e.target.closest('.js-mobile-notifications-toggle').getAttribute('data-mobiletoggle');
  const dropdown = document.getElementById(dropdownID);
  toggleMobileUserMenu(dropdown);
}

mobileToggle && [...mobileToggle].forEach((elem) => {
  elem.addEventListener('click', handleMobileNotification);
});

$(".js-mobile-notifications-toggle[data-mobiletoggle=user-dropdown-2]").on("click", handleMobileUserMenu);

$("#user-dropdown-2, #notification-dropdown-2").on("close", function () {
  if (this.classList.contains("is-open")) {
    toggleDropdownMenu(this);
    toggleMobileUserMenu(this);
  }
});

mobileToggle && $(document).on('keydown', function (e) {
  if (e.keyCode === 27) {
    document.body.classList.remove("is-unscrollable");
    $('.is-mobile').removeClass("is-mobile");
    const $tabloop = $('.js-tabloop');
    $tabloop.tabloop();
    $(`.js-mobile-notifications-toggle[data-mobiletoggle=${$tabloop.attr("id")}]`).first().focus();
    $tabloop.attr("aria-hidden", true);
  }
});

function initMarkAllButton() {
  [...markAllAsRead].forEach((button) => {
    const hasNewNotifications = document.getElementsByClassName('user-notification__new-notification').length > 0;
    if (!hasNewNotifications) button.setAttribute('disabled', "");
    button.addEventListener("keydown", function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault();
        event.stopPropagation();
        markAllAsSeen(button);
      }
    });

    button.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      markAllAsSeen(button);
    });
  });

  $('.navigation__desktop .js-mark-all-as-read').on('blur', (e) => {
    if (e.relatedTarget && $('#notification-dropdown').find(e.relatedTarget).length === 0) {
      $('#notification-dropdown').foundation('close');
    }
  })
}

const notificatioMenu = $('#dynamicNotificationMenu').get(0);
if (notificatioMenu) {
  notificationMenuView.desktopMenu = new App.components.ContentMenu({
    model: new Backbone.Model(),
    el: $("#dynamicNotificationMenu"),
    url: notificatioMenu.getAttribute('data-url'),
    templateSelector: "#notification-menu-template",
    onShow: initMarkAllButton
  })

  notificationMenuView.mobileMenu = new App.components.ContentMenu({
    model: new Backbone.Model(),
    el: $("#dynamicMobileNotificationMenu"),
    url: notificatioMenu.getAttribute('data-url'),
    templateSelector: "#notification-mobile-menu-template",
    onShow: initMarkAllButton
  })
}

const userMenu = $('.js-dynamic-user-menu ');
if (userMenu.length > 0) {
  const userMenuView = new App.components.ContentMenu({
    model: new Backbone.Model(),
    el: $(".js-dynamic-user-menu"),
    url: userMenu.get(0).getAttribute('data-url'),
    templateSelector: "#user-menu-template"
  })

  const userMobileMenuView = new App.components.ContentMenu({
    model: new Backbone.Model(),
    el: $(".js-dynamic-user-menu"),
    url: userMenu.get(0).getAttribute('data-url'),
    templateSelector: "#user-menu-template",
    onShow: function() {
      const dropdown = document.getElementById("user-dropdown-2");
      toggleMobileUserMenu(dropdown);
    }
  })

  $('a[data-toggle="user-dropdown"]').on('click', function () {
    userMenuView.show();
  });

  $('a[data-mobiletoggle="user-dropdown-2"]').on('click', function () {
    userMobileMenuView.show();
  });
}
